import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Component from './component';
import validate from './validate';

function mapStateToProps(state) {
  const { resetPin = {} } = state.form;
  const { isLoading } = state.loading;
  const formValues = resetPin.values;
  return {
    formValues,
    isLoading,
  };
}

function parseQueryString(params){
  params = params.substring(1);
  const res = new URLSearchParams(params);
  return Object.assign({}, ...Array.from(res, ([k, v]) => ({ [k]: v }) ));
}

const params = parseQueryString(location.search);

const Form = reduxForm({
  form: 'resetPin',
  initialValues: {
    token: params.token
  },
  validate,
})(Component);

export default connect(
  mapStateToProps,
  () => ({}),
)(Form);

