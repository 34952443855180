const BASE_URL_USER_DEV = 'https://myptm-customer-api-dev.vsan-apps.playcourt.id/customer/v1';
const BASE_URL_USER_STAGING = 'https://api-stg.mypertamina.id/customer/v1';
const BASE_URL_USER_PROD = 'https://api.mypertamina.id/customer/v1';

let BASE_URL_USER = process.env.NODE_ENV === 'production' && !__DEV__ ? !__UAT__ ? BASE_URL_USER_PROD : BASE_URL_USER_STAGING : BASE_URL_USER_DEV;

const services = {
  PUT_RESET_PIN: `${BASE_URL_USER}/auth/pin/reset`,
};

export default services;

