import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

export default class TextFieldPin extends React.Component {
  _inputStatusClass() {
    const { customMeta, meta } = this.props;
    const { active, invalid, touched, valid } = { ...meta, ...customMeta };

    if (touched && invalid) {return styles.invalid;}
    if (touched && valid) {return styles.active;}
    if (active) {return styles.active;}
  }

  render() {
    const { className, customMeta, input, inputProps, meta } = this.props;
    const { error, invalid, touched } = { ...meta, ...customMeta };
    const customClass = [styles.root, className, this._inputStatusClass()].filter(Boolean).join(' ');
    const customClassInput = [styles.input, this._inputStatusClass()].filter(Boolean).join(' ');

    return (
      <div className={customClass}>
        <div className={customClassInput}>
          <input {...input} {...inputProps} />
        </div>
        <span className={styles.error}>{touched && invalid && error}</span>
      </div>
    );
  }
}

TextFieldPin.defaultProps = {
  className: '',
  customMeta: {},
  input: {},
  inputProps: {},
  meta: {},
};

TextFieldPin.propTypes = {
  className: PropTypes.string,
  customMeta: PropTypes.object,
  input: PropTypes.object,
  inputProps: PropTypes.object,
  meta: PropTypes.object,
};
