import images from './images';
import routes from './routes';
import services from './service';

export const IMAGES = images;
export const ROUTES = routes;
export const SERVICES = services;
export const AUTH_KEY = 'Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==';
export const ENCRYPT_KEY_RESET_PIN = 'BDE540BD7E96ECAB33D0216EF003F53C';
export const IV_RESET_PIN = '1234567890123456';
