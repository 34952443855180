import validateInput from '../../../utils/validateInput';

const validate = values => {
  const {
    confirmation_pin,
    pin
  } = values;
  const errors = {
    pin: validateInput(pin, ['required', 'is-number', 'char-6']),
    confirmation_pin: validateInput(confirmation_pin, ['required', 'is-number', 'char-6', 'verified-pin'], pin),
  };

  return errors;
};


export default validate;
