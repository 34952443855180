import { ACTIONS } from '../../constants';

const initialState = {
  message: '',
  success: false
};

export default function reducer(state = initialState, action) {
  const { RESET_PIN_SENT } = ACTIONS;
  const { message, success, type } = action;

  switch (type) {
    case RESET_PIN_SENT:
      return {
        ...state,
        message,
        success,
        type
      };
    default:
      return state;
  }
}
