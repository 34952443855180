import CryptoJS from 'crypto-js';
import fetch from '../../utils/fetch';
import { ACTIONS } from '../../constants';
import { AUTH_KEY, ENCRYPT_KEY_RESET_PIN, IV_RESET_PIN, SERVICES } from '../../configs';


export function sendData(data) {
  return dispatch => {
    const options = {
      data: encryptPin(data),
      url: SERVICES.PUT_RESET_PIN,
      method: 'PUT',
      headers: {
        'Authorization': AUTH_KEY,
      },
    };

    dispatch(loadingAction());

    fetch(options)
      .then(() => {
        dispatch(resetPinSent());
        dispatch(doneLoadingAction());
      })
      .catch((res) => {
        dispatch(errorFetched(res));
        dispatch(doneLoadingAction());
      });
  };
}

function encryptPin(payload) {
  const encrypted = CryptoJS.AES.encrypt(payload.pin, 
    CryptoJS.enc.Latin1.parse(ENCRYPT_KEY_RESET_PIN),{
      iv: CryptoJS.enc.Latin1.parse(IV_RESET_PIN),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString();

  payload.pin = encrypted;
  return payload;
}

function errorFetched({ code, message }){
  if(code === 409 || code === 400){
    message = 'Tidak ada token yang dikirim';
  } else if(code === 403){
    message = 'Token salah atau sudah expired. Kirim lagi permintaan reset pin di aplikasi.';
  }
  return{
    type: ACTIONS.RESET_PIN_SENT,
    message
  };
}

function resetPinSent(){
  return{
    type: ACTIONS.RESET_PIN_SENT,
    success: true
  };
}

function loadingAction() {
  return { type: ACTIONS.LOADING };
}

function doneLoadingAction() {
  return { type: ACTIONS.DONE_LOADING };
}
