import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';

export default class Button extends React.Component {
  render() {
    const { children, className, disabled, onClick, variant } = this.props;
    const buttonClass = styles[variant];
    const disabledClass = disabled && styles.disabled;
    const customClass = [styles.root, buttonClass, disabledClass, className].filter(Boolean).join(' ');

    return (
      <button className={customClass} disabled={disabled} onClick={onClick}>{children}</button>
    );
  }
}

Button.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  onClick: () => {},
  variant: 'small'
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['very-small','small','medium','large']),
};
