import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import loading from './loading';
import resetPin from '../pages/ResetPin/reducer';

const rootReducer = combineReducers({
  loading,
  form: formReducer,
  resetPin,
  routing: routerReducer,
});

export default rootReducer;
