import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.css';
import { Field } from 'redux-form';
import Button from '../../elements/Button';
import TextFieldPin from '../../elements/TextFieldPin';
import { IMAGES } from '../../../configs';

export default class FormResetPin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: {
        pin: false,
        confirmation_pin: false
      }
    };
  }

  _handleVisibility = (name) => {
    const { visibility } = this.state;
    this.setState({
      visibility : {
        ...visibility,
        [name] : !visibility[name]
      }
    });
  }

  _handleNumber = (e) => {
    if(!/^\d+$/.test(e.target.value)){
      if(e.target.value.length > 0){
        e.preventDefault();
      }
    }
  }

  _renderFormReset(){
    const { handleSubmit, message, invalid, isLoading } = this.props;
    const disabled = invalid || isLoading;
    return(
      <>
        <div className={styles.formReset}>
          <form onSubmit={handleSubmit}>
            {this._renderTextField('pin', 'PIN Baru (6 Digit)', true)}
            {this._renderTextField('confirmation_pin', 'Ulangi PIN Baru (6 Digit)', true)}
            <p className={styles.errorMsg}>{message}</p>
            <Button disabled={disabled} type="submit">RESET PIN</Button>
          </form>
        </div>
      </>
    );
  }

  _renderResetSuccess(){
    return(
      <div className={styles.success}>
        <img src={IMAGES.CHECK} />
        <h1>PIN Berhasil direset!</h1>
        <p>PIN kamu berhasil direset. Silakan masuk kembali ke aplikasi untuk melakukan login.</p>
      </div>
    );
  }

  _renderTextField(name, placeholder, required) {
    const { visibility } = this.state;
    const inputProps = {
      id: name,
      pattern: '[0-9]*',
      inputMode: 'numeric',
      maxLength: 6,
      placeholder,
      required: required && 'required',
      type: visibility[name] ? 'text' : 'password',
    };

    return (<div className={styles.pinInput}>
      <Field component={TextFieldPin} inputProps={inputProps} name={name}
        onChange={this._handleNumber.bind(this)} placeholder={placeholder}/>
      <img onClick={() => this._handleVisibility(name)}
        src={visibility[name] ? IMAGES.VISIBILITY_OFF : IMAGES.VISIBILITY}/>
    </div>);
  }

  render() {
    const { success } = this.props;

    return (
      <>
        <header className={styles.headerReset}>
          <h1 className={styles.textCenter}>Reset PIN</h1>
        </header>
        <main className={styles.root}>
          <div className={styles.pageDesc}>
            <figure>
              <img src={IMAGES.LOGO} />
            </figure>
          </div>
          {success ? this._renderResetSuccess() : this._renderFormReset()}
        </main>
      </>
    );
  }
}

FormResetPin.defaultProps = {
  actions: {},
  classes: {},
  className:'',
  handleSubmit:()=>{},
  initialValues:{},
  invalid:false,
  isLoading: false,
  message: '',
  success: false,
};

FormResetPin.propTypes = {
  actions: PropTypes.object,
  classes: PropTypes.object,
  className:PropTypes.string,
  handleSubmit:PropTypes.func,
  initialValues:PropTypes.object,
  invalid:PropTypes.bool,
  isLoading: PropTypes.bool,
  message: PropTypes.string,
  success: PropTypes.bool
};
