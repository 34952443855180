import React from 'react';
import PropTypes from 'prop-types';
import ResetPinForm from '../../components/forms/ResetPin';
import { ACTIONS } from '../../constants';

export default class ResetPin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      message: '',
      success: this.props.success
    };
  }

  componentWillReceiveProps(nextProps) {
    const { RESET_PIN_SENT } = ACTIONS;
    const { message, type, success } = nextProps;
    if( type === RESET_PIN_SENT ) {
      this.setState(
        { message,
          success }
      );
    }
  }

  _handleSubmit = (values) => {
    const { actions } = this.props;
    const submittedValues = { ...values };
    delete submittedValues.confirmation_pin;
    actions.sendData(submittedValues);
  }

  render() {
    const { message, success } = this.state;

    return (
      <ResetPinForm message={message} onSubmit={this._handleSubmit.bind(this)} success={success} />
    );
  }
}

ResetPin.defaultProps = {
  actions: {},
  message: '',
  success: false,
  type: ''
};

ResetPin.propTypes = {
  actions: PropTypes.object,
  message: PropTypes.string,
  success: PropTypes.bool,
  type: PropTypes.string
};
