export default function validateInput(value = '', rules, param = '') {
  for (let idx = 0; idx < rules.length; idx++) {
    if (rules[idx] === 'required' && !value) {
      return 'Harus diisi';
    } else if (rules[idx] === 'char-6' && value.length !== 6) {
      return 'Harus terdiri dari 6 karakter';
    }  else if (rules[idx] === 'is-number' && isNaN(value)) {
      return 'Harus Angka';
    } else if (rules[idx] === 'verified-pin' && value !== param) {
      return 'Pin tidak sesuai';
    }
  }
  return '';
}
